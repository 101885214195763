const CloseIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.77297 7.93414C8.57771 7.73887 8.26113
          7.73887 8.06586 7.93414C7.8706 8.1294 7.8706 8.44598 8.06586
          8.64124L11.4246 12L8.06586 15.3588C7.8706 15.554 7.8706 15.8706
          8.06586 16.0659C8.26113 16.2611 8.57771 16.2611 8.77297 16.0659L12.1317
          12.7071L15.4905 16.0659C15.6857 16.2611 16.0023 16.2611 16.1976
          16.0659C16.3929 15.8706 16.3929 15.554 16.1976 15.3588L12.8388 12L16.1976
          8.64124C16.3929 8.44598 16.3929 8.1294 16.1976 7.93414C16.0023 7.73887
          15.6857 7.73887 15.4905 7.93414L12.1317 11.2929L8.77297 7.93414Z"
        fill="#247251"
      />
    </svg>
  );
};

export default CloseIcon;
